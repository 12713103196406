import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/layout';
export const _frontmatter = {
  "title": "About"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`About`}</h1>
    <p>{`We want to make development for the web
`}<a parentName="p" {...{
        "href": "https://johno.com/jsx-as-a-design-tool-data-structure"
      }}>{`visual`}</a>{`.
So, we're building a tool, Blocks, that can:`}</p>
    <ul>
      <li parentName="ul">{`read in source code`}</li>
      <li parentName="ul">{`edit the code visually`}</li>
      <li parentName="ul">{`returns modified source code`}</li>
      <li parentName="ul">{`can be extended and used by other projects`}</li>
    </ul>
    <p>{`It's still in the very early stages of development, but Blocks
is being built in a way where different layers of the library
can be used in different ways by projects with varying needs.`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/1424573/69600586-5ca1ab00-0fce-11ea-9f11-bdca11860cab.png",
        "alt": "image"
      }}></img></p>
    <p><strong parentName="p">{`Need something lower level to manipulate JSX in your own design
or development tool?`}</strong>{` Use the babel transforms and queries.`}</p>
    <p><strong parentName="p">{`Do you want your own components in the editor?`}</strong>{` Build your
own Blocks and pass them to the editor.`}</p>
    <p><strong parentName="p">{`Do you want to drastically customize the UI?`}</strong>{` Eventually you
will be able to use the plugin system (it's currently in the
planning phases).`}</p>
    <p><strong parentName="p">{`Do you use Bootstrap, Bulma, or some other CSS library rather
than Theme UI?`}</strong>{` Build your own components and expose your own
controls!`}</p>
    <p><a parentName="p" {...{
        "href": "/vision"
      }}>{`Read about the vision of the project `}{`→`}</a></p>
    <h2>{`Authors`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://johno.com"
        }}>{`John Otander`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://jxnblk.com"
        }}>{`Brent Jackson`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://christopherbiscardi.com"
        }}>{`Chris Biscardi`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/blocks/blocks/graphs/contributors"
        }}>{`... our wonderful contributors`}</a></li>
    </ul>
    <h2>{`Thanks`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "/prior-art/"
          }}>{`Prior art`}</a></strong>{`: Blocks didn't come out of a vacuum`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "/uses/"
          }}>{`Uses`}</a></strong>{`: Tools that Blocks uses`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://gatsbyjs.org"
          }}>{`Gatsby`}</a></strong>{`: For incubating Blocks development`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://twitter.com/fk"
          }}>{`Florian Kissling`}</a></strong>{`: For the logo`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      